import moment from "moment";
import { LoadState } from "../../constants/enums";
import { STANDARD_APP_DATE_FORMAT, STANDARD_APP_TIME_FORMAT } from "../../constants/constants";

export interface INoticesState {
  list: INotices[];
  totalRecords: number;
  loading: LoadState;
}

export interface INotices {
  incremental_number: number | null;
  notice_code: string | null;
  notice_type: string | null;
  description: string | null;
  customer: number | null;
  customer_name: string | null;
  policy_number: string | null;
  line_of_business: string | null;
  producer_code: string | null;
  csr_code: string | null;
  branch_code: string | null;
  status_other: string | null;
  action_taken: string | null;
  file_upload: string | null;
  notes: string | null;
  assigned_to_id: number | null;
  status: string;
  due_date: string | null;
  create_ts: string | null;
  due_time: string | null;
  insurer_id: string | null;
  policy_type: string | null;
  message_type: string | null;
  mobile_number: string | null;
  email: string | null;
}

export const initialNoticesState: INoticesState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
};

export const initialNotices: INotices = {
  incremental_number: null,
  notice_code: null,
  notice_type: null,
  description: null,
  customer: null,
  customer_name: null,
  policy_number: null,
  line_of_business: null,
  producer_code: null,
  csr_code: null,
  branch_code: null,
  status_other: null,
  action_taken: null,
  file_upload: null,
  notes: null,
  assigned_to_id: null,
  status: "Open",
  due_date: moment().format(STANDARD_APP_DATE_FORMAT),
  create_ts: null,
  due_time: moment().endOf("day").toString(),
  insurer_id: null,
  policy_type: null,
  email: null,
  message_type: null,
  mobile_number: null
};


export interface ICustomerAllPolicyDetail {
  policy_status: string;
  customer_policy_id: string;
  customer_id: string;
  customer_name: string;
  customer_code: string;
  policy_number: string;
  policy_sub_type: string;
  line_of_business: string;
  producer_one_code: string;
  producer_one: string;
  producer_two: string;
  producer_two_code: string;
  producer_three: string;
  producer_three_code: string;
  insurer: string;
  marketer_code: string;
  marketer: string;
  effective_date: string;
  expiry_date: string;
  branch_code: string;
  insert_ts: string;
}
